/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */



(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        clone();
        barba();
        menu();
        newsletter();
        aside();
        header();
        //setArticles();
        winResize();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  var cat = $('body').data('category');

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  function winResize(){
    $(window).on('resize', windowResize);
    var doc = document.documentElement;
    doc.style.setProperty('--app-height', window.innerHeight + 'px');
    windowResize();
    var resizeTimer;
    
    function windowResize(){
      sizeBody();
      watchMobileScroll();
      $('body').addClass('resize');
      doc.style.setProperty('--app-height', window.innerHeight + 'px');
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function(){
        $('body').removeClass('resize');
        doc.style.setProperty('--app-height', window.innerHeight + 'px');
      }, 500);
    }
  }

  function watchMobileScroll(){
    console.log('watch mobile scroll');
    var $header = $('header');
    if($('article.active').length > 0 ) {
      var $article = $('article.active');
      if($(window).width() < 1024){
          $article.off('scroll').on('scroll', function(){
          console.log($article.scrollTop());
          var st = $article.scrollTop();
          console.log(st)
          $header.css('transform', 'translateY(-'+ st +'px)');
        });
      } else {
        $article.off('scroll')
        $header.css('transform', 'translateY(0px)');
      }
      
    }
  }
  
  function clone(){
    var $main = $('.articles main'),
        $window = $(window),
        $cloned = 0,
        $bc = $('#BandsContainer'),
        $logo = $('.brand-logo svg'),
        $articles = $('.articles');

   

    $('.articles main article').each(function(){
      $(this).attr('data-index', $(this).index());
      $main.append($(this).clone().addClass('cloned'));
    });
    $cloned = $('.cloned').first();

    sizeBody();
    
    $window.on('scroll', function(){
      var st = $(window).scrollTop();
      console.log($cloned.position().top);

      if( st >= $cloned.position().top) {
        $window.scrollTop(0);
        //$articles.css('transform', 'translateY('+ 0 +'px)')
      }

      return true;
      if( st >= $('body')[0].scrollHeight/2 - 1) {
        $window.scrollTop(0);
        $articles.css('transform', 'translateY('+ 0 +'px)')
      }
      $articles.css('transform', 'translateY(-'+ st +'px)')
      var factor = ($main.height())/($bc.height() - $logo.height()/2 + 30);
      var y = -($window.scrollTop())/factor;
          $bc.css('transform', 'translateY(' + y + 'px)');
    })
  }

  function sizeBody(){
    //$('#barba-wrapper').css('height', $('.post-article').height() * $('.post-article').length);
  }

  function barba(){
    console.log('barba');
    Barba.Pjax.start();
  }

  function setArticles(){
    //category is set filter articles
    console.log('set articles');
    if(cat != '') {
      console.log(cat);
      $('[data-category]').addClass('hide');
      $('[data-category="'+cat+'"]').removeClass('hide');
    }
  }

  function aside(){
    var $hamburger = $('#Hamburger'),
        $aside = $('#About');
    console.log('aside')
    $hamburger.on('click', function(){
      $aside.toggleClass('active');
      $('body').toggleClass('aside-active');
    });
  }

  function newsletter(){
    console.log('newsletter test')
    if($.cookie('newsletter') == undefined){
        setTimeout(function(){
            $('#Newsletter').fadeIn();
        }, 1500);
        
    } 
      $('.newsletter-close').on('click', function(){
          $('#Newsletter').fadeOut();
          $.cookie('newsletter', 'hide', { expires: 7 });
      });

      var $form = $('#Newsletter form');

      if ( $form.length > 0 ) {
          $('#Newsletter form .submit').bind('click', function ( event ) {
              if ( event ) event.preventDefault();
              // validate_input() is a validation function I wrote, you'll have to substitute this with your own.
              register($form);
          });
      }

      function register($form) {
          $.ajax({
              type: $form.attr('method'),
              url: $form.attr('action'),
              data: $form.serialize(),
              cache       : false,
              dataType    : 'json',
              contentType: "application/json; charset=utf-8",
              error       : function(err) { alert("Could not connect to the registration server. Please try again later."); },
              success     : function(data) {
                  console.log(data);
                  if (data.result != "success") {
                    var msg = data.msg;
                        msg = msg.split('-').pop();
                    $('#mce-error-response').show().text(msg);
                      // Something went wrong, do something to notify the user. maybe alert(data.msg);
                  } else {
                    $('#mce-error-response').hide();
                    $('#mce-success-response').show().text('Thank you for signing up!');
                    setTimeout(function(){
                      $('#Newsletter').fadeOut();
                      $.cookie('newsletter', 'hide', { expires: 365 });
                    }, 1500);
                      // It worked, carry on...
                  }
              }
          });
      }
  }

  function header(){
    var $articles = $('main article'),
        $bandSVG = $('#Bands'),
        $bands = $('#BandsContainer'),
        $window = $(window),
        $header = $('header'),
        $mbc = $('.mobile-band-container');

        var $bc = $('#BandsContainer'),
        $sh = $('.site-header'),
        offset = $(window).width() > 980 ? 0 : $('.articles').position().top; 

        $window.on('scroll', function(){
          //move bands
          //- $('.band-dummy').height()
          var st = $window.scrollTop();
          var articles_height = $('.articles').height();
          var factor = (articles_height)/($bc.height());
          console.log(factor)
          var y = -($(window).scrollTop())/factor;
          //console.log($('body')[0].scrollHeight);
          console.log(y);
          var num = -$window.scrollTop();
          $bands.css('transform', 'translateY('+ y +'px)');
          $mbc.css('transform', 'translateY(-'+ st +'px)');
        });
        /*
        $window.on('mousemove', function(e){
          var y = e.clientY,
              num = -( ($bands.height()/($window.height()+130)) * y );

          console.log(num);
          $bands.css('transform', 'translateY('+ num +'px)');
        })
        */
  }

  function menu(){
    var $articles = $('main article'),
        $close = $('.post-article .close'),
        $slug = $('.post-article .post-slug');
    
    //set body padding and color for mobile
    $('body').css('background-color', $('main article:first-child').data('color'))

    $('.post-article').on('click', function(){
      console.log('clicked article') 
      var $this = $(this);
      
      if($this.hasClass('cloned')){
        //alert('is clone')
        var index = $this.data('index');
        var $original_article = $('.post-article[data-index="'+index+'"]').eq(0).position().top;
        //$(window).scrollTop(0);
      }
      if(!$this.hasClass('active')){
        //alert('scroll')
        openArticle($this);
        closeAside();
      } else {
        //closeArticle($this);
      }
     
    });
    $close.on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      
      if(cat != '') {
        Barba.Pjax.goTo($('.post-slug').attr('href'));
      } else {
        Barba.Pjax.goTo('/');
      }
      if($('.post-article.active')){
        var $post = $(this).closest('.post-article');
        closeArticle($post)
      }
      
    });
    
    $slug.on('click', function(e){
      e.preventDefault();
      e.stopPropagation();
      //Barba.Pjax.goTo('/');
       $this = $(this);
       cat = $this.data('slug');
      if($('.post-article.active')){
        var $post = $(this).closest('.post-article');
        closeArticle($post);
        
        setTimeout(function(){ 
          window.location.href = $this.attr('href');
          //setArticles();
        }, 300);
      }
    });
    
    $('.brand-logo').on('click', function(e){
      e.preventDefault();
      e.stopPropagation();
      
      closeAside();
      if($('.post-article.active')){
        var $post = $(this).closest('.post-article');
        closeArticle($post)
      }
      if(cat != '') {
        window.location.href = $(this).attr('href');
      } else {
        Barba.Pjax.goTo('/');
      }
      
      
    });
    function closeAside(){
      var $aside = $('#About');
      $aside.removeClass('active');
      $('body').removeClass('aside-active');
    }

    var scrollPos = 0;

    function openArticle($post){
      var $post = $post,
          $bands = $('.band');

      console.log('open article')
      var $articles = $('main article');
      $articles.removeClass('active').addClass('not-active');
      //console.log($this.position().top)
      //$('body').scrollTop($this.position().top)
      $post.addClass('active');
      $bands.css('background-color', $post.data('color'));
      Barba.Pjax.goTo($post.find('a').attr('href'));
      $('body').addClass('article-view');

      scrollPos = $(window).scrollTop();
      watchMobileScroll();

      //$('body, html').animate({scrollTop:0 }, 300, 'linear');
    }
    function closeArticle($post){
      console.log('close article')
      var $post = $post,
          $bands = $('.band');
      var $articles = $('main article');
      $articles.removeClass('not-active active');
      //console.log($this.position().top)
      //$('body').scrollTop($this.position().top)
      $post.removeClass('active not-active');

      $('body, html').stop().animate({scrollTop: scrollPos }, 300, 'linear', function(){
        sizeBody();
      });
      $bands.each(function(){
        $(this).css('background-color', $(this).data('color'));
      });
      $('body').removeClass('article-view');
    }
    $('.post-article .post-title > a').on('click', function(e){
      e.preventDefault();
    });
    console.log('menu')
  }

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
